<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row>
      <v-col>

        <v-card flat class="card_action">

            <v-row align-items="center">
              <v-col class="flex_end">
                <v-btn
                  color="primary"
                  depressed
                  tile
                  @click="add_project()"
                >
                  Dodaj
                </v-btn>
              </v-col>
            </v-row>

        </v-card>
        
        <v-form
          ref="form" 
          v-model="valid"
          lazy-validation
        >
          <v-card>
            <v-card-text>
              <v-row>
                <v-col>


                  <v-text-field
                    v-model="name"
                    label="Nazwa projektu"
                    :rules="$store.state.rules.not_null"
                  ></v-text-field>

                  <v-textarea
                    v-model="description"
                    label="Opis"
                    color="primary"
                  ></v-textarea>

                  <!-- <v-select
                    v-model="selected_customer"
                    :items="customers"
                    item-text="name"
                    item-value="id"
                    class="classic_select"
                    label="Klienci"
                  ></v-select> -->

                  <v-row>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="budget_netto"
                        label="Budżet netto"
                        suffix="zł"
                        :rules="$store.state.rules.not_null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-text-field
                        v-model="budget_brutto"
                        label="Budżet brutto"
                        suffix="zł"
                        :rules="$store.state.rules.not_null"
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-autocomplete
                    v-model="selected_customer"
                    :items="customers"
                    item-text="name"
                    item-value="id"
                    no-data-text="Brak wyników"
                    label="Klienci"
                    :rules="$store.state.rules.not_null"
                  ></v-autocomplete>

                  <v-autocomplete
                    v-model="selected_guardian"
                    :items="workers"
                    item-text="name"
                    item-value="id"
                    no-data-text="Brak wyników"
                    label="Opiekun projektu"
                    :rules="$store.state.rules.not_null"
                  ></v-autocomplete>

                  <!-- <v-select
                    v-model="selected_guardian"
                    :items="workers"
                    item-text="name"
                    item-value="id"
                    label="Opiekun projektu"
                    :rules="$store.state.rules.not_null"
                  ></v-select> -->

                  <v-autocomplete
                    v-model="selected_workers"
                    :items="workers"
                    item-text="name"
                    item-value="id"
                    no-data-text="Brak wyników"
                    label="Przypisani pracownicy"
                    chips
                    multiple
                    :rules="$store.state.rules.not_null"
                  ></v-autocomplete>

                  <v-menu
                    v-model="date_picker"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="date_finished"
                        label="Termin projektu"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date_finished"
                      @input="date_picker = false"
                      :min="today"
                      locale="pl-pl"
                      color="primary"
                    ></v-date-picker>
                  </v-menu>
                
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>

      </v-col>
    </v-row>
    
  </div>
</template>

<script>
export default {
  data: () => ({
    valid: false,
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista projektów',
        disabled: false,
        to: '/projekty',
      },
      {
        text: 'Nowy projekt',
        disabled: true,
        to: '/projekt',
      },
    ],

    date_picker: false,
    date_finished: new Date().toISOString().substr(0, 10),

    today: new Date().toISOString().substr(0, 10),

    name: '',
    description: '',
    selected_customer: null,
    selected_workers: null,
    selected_guardian: null,

    budget_netto: null,
    budget_brutto: null,

    customers: [],
    workers: [],
  }),
  methods: {
    add_project() {
      if(this.$refs.form.validate()) {
        this.$store.commit('loader');
        let formData = new FormData();
        formData.append("name", this.name);
        formData.append("description", this.description);
        formData.append("budget_netto", this.budget_netto);
        formData.append("budget_brutto", this.budget_brutto);
        if(this.selected_customer) formData.append("assign_user", this.selected_customer);
        formData.append("guardian", this.selected_guardian);
        formData.append("finished_at", this.date_finished);
        console.log(this.selected_workers);
        for(const item in this.selected_workers){
          formData.append("assign["+item+"]", this.selected_workers[item]);
        }

        this.$axios({url: this.$store.state.api +'/project', data: formData, method: 'POST', headers: {'Content-Type': 'multipart/form-data'} })
          .then(resp => {
            this.$router.push('/projekty');
            this.$store.commit("snackbar", {
              text: "Dodano projekt",
              color: "primary",
              btn_color: "white"
            });
          })
          .catch(err => {
              this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
          })
          .then (() => {
            this.$store.commit('loader_off');
          })
      }
    },
  },
  mounted(){
    this.$store.commit('loader');

    let loader = 2;

    this.$axios({url: this.$store.state.api +'/customer', data: {}, method: 'GET' })
      .then(resp => {
        this.customers = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })
    this.$axios({url: this.$store.state.api +'/auth/user', data: {}, method: 'GET' })
      .then(resp => {
        this.workers = resp.data;
        console.log(resp.data);
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        loader--;
        if(!loader)this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
</style>